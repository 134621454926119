<template>
  <div id="individual-display-data">
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img :src="records.MemberImagePath" :alt="records.MemberName" />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span class="font-size-h3 symbol-label font-weight-boldest">JM</span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >{{ records.MemberName }}</a
                >
                <a href="#"
                  ><i class="flaticon2-correct text-success font-size-h5"></i
                ></a>
              </div>

              <div class="my-lg-0 my-3">
                <a
                  href="#"
                  class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                  >Email</a
                >
                <a href="#" class="btn btn-sm btn-info font-weight-bolder text-uppercase"
                  >Call</a
                >
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i>
                    {{ records.MobileNo }}
                    <i class="font-size-h5" v-bind:class="records.MobileNoIcon"></i>
                  </a>
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><i class="flaticon2-new-email mr-2 font-size-lg"></i>
                    {{ records.EmailId }}
                    <i class="font-size-h5" v-bind:class="records.EmailIdIcon"></i>
                  </a>
                  <!-- <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold"
                    ><i class="flaticon2-placeholder mr-2 font-size-lg"></i
                    >Melbourne</a
                  > -->
                </div>

                <span class="font-weight-bold text-dark-50">
                  <p v-html="records.AboutMe"></p>
                </span>
              </div>

              <!-- <div
                class="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8"
              >
                <span class="font-weight-bold text-dark-75">Progress</span>
                <div class="progress progress-xs mx-3 w-100">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 63%;"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span class="font-weight-bolder text-dark">78%</span>
              </div> -->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm"> Membership Id </span>
              <span class="font-weight-bolder font-size-h5">
                <!-- <span class="text-dark-50 font-weight-bold">$</span> -->
                {{ records.MembershipId }}
              </span>
            </div>
          </div>

          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm"> Age </span>
              <span class="font-weight-bolder font-size-h5">
                <!-- <span class="text-dark-50 font-weight-bold">$</span> -->
                {{ records.Age }}
              </span>
            </div>
          </div>
          <!--end::Item-->

          <!-- <div v-for="(item, index) in records.ImportantDate">
            <p><h3>  {{ item.TypeTxt }} : {{ item.DateTxt }} </h3></p>
          </div> -->

          <!--begin::Item-->
          <div
            class="d-flex align-items-center flex-lg-fill mr-5 mb-2"
            v-for="(item, index) in records.ImportantDate"
          >
            <span class="mr-4">
              <i class="flaticon-confetti display-4 text-muted font-weight-bold"></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm"> {{ item.TypeTxt }} </span>
              <span class="font-weight-bolder font-size-h5">
                {{ item.DateTxt }}
              </span>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <!-- <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >648 Comments</span
              >
              <a href="#" class="text-primary font-weight-bolder">View</a>
            </div>
          </div> -->
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {},
  beforeMount() {},
};
</script>
