<template>
  <div id="individual-display-data">
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header h-auto border-0">
        <v-row wrap>
          <v-col cols="12" md="12">
            <p></p>
            <h5 class="blue--text text--lighten">Address</h5>
            <p>{{ records.Street1 }} {{ records.Street2 }}</p>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="12" md="4">
            <h5 class="blue--text text--lighten">City</h5>
            <p>{{ records.City }} {{ records.Pincode }}</p>
          </v-col>
          <v-col cols="12" md="4">
            <h5 class="blue--text text--lighten">District</h5>
            <p>{{ records.District }}</p>
          </v-col>
          <v-col cols="12" md="4">
            <h5 class="blue--text text--lighten">State</h5>
            <p>{{ records.State }}</p>
          </v-col>
        </v-row>
        <div class="card-toolbar"></div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body">
        <v-container>
          <v-row wrap>
            <v-col cols="12" md="12"> </v-col>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62948.04921135481!2d77.92317084801712!3d9.57343964490065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b012c93bcb2b013%3A0xd717404a42ed026e!2sVirudhunagar%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1624629979271!5m2!1sen!2sin"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </v-row>
        </v-container>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {},
  beforeMount() {},
};
</script>
