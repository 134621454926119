<template>
  <div id="individual-display-data">
    <v-container class="py-0">
      <v-row wrap v-if="!ResultFlag">
        <v-col align="center" cols="12" md="12">
          <h3>Access denied</h3>
        </v-col>
      </v-row>
      <v-row wrap v-if="records.length > 0 && ResultFlag">
        <v-col align="center" cols="12" md="12">
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> -->
          <br />
          <v-data-table
            class="elevation-1"
            :single-select="false"
            v-model="selected"
            :headers="tableColumns1"
            :items="records"
            :items-per-page="50"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
          >
            <template v-slot:item.ActiveStatusTxt="{ item }">
              <v-chip
                :color="getActiveStatusColor(item.ActiveStatusTxt)"
                draggable
                dark
                >{{ item.ActiveStatusTxt }}</v-chip
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <br />
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {
      tableColumns1: [],
      selected: [],
      search: "",
      rows: [],
      ResultFlag: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
  },
  beforeMount() {
    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    if (RoleId != "") {
      this.ResultFlag = true;
      this.refreshPageData();
      if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
        this.tableColumns1.push(
          { text: "Lom Name", value: "LomName" },
          { text: "Year", value: "Year" },
          { text: "Half", value: "HalfTxt" },
          { text: "Date", value: "DuesDateTxt" }
        );
      } else {
        this.ResultFlag = false;
      }
    } else {
      this.$router.push("/access-denied");
    }
  },
};
</script>
