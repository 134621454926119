<template>
  <div id="individual-display-data">
    <v-container class="py-0">
      <v-row wrap v-if="!ResultFlag">
        <v-col align="center" cols="12" md="12">
          <h3>Access denied</h3>
        </v-col>
      </v-row>
      <v-container class="py-0" v-if="LoadingFlag">
        <v-skeleton-loader
          v-bind="attrs"
          type="table-tbody"
        ></v-skeleton-loader>
      </v-container>
      <v-container class="py-0" v-if="!LoadingFlag">
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-tooltip bottom v-if="CreateButtonFlag">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click="previewData(item, e)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-plus-box</v-icon
                >
              </template>
              <span>Add New</span>
            </v-tooltip>
            <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field> -->
            <br />
            <v-data-table
              v-if="TableData.length > 0 && ResultFlag"
              class="elevation-1"
              :single-select="false"
              v-model="selected"
              :headers="TableHeader"
              :items="TableData"
              :items-per-page="50"
              :search="search"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
              }"
            >
              <template v-slot:item.ActiveStatusTxt="{ item }">
                <v-chip
                  :color="getActiveStatusColor(item.ActiveStatusTxt)"
                  draggable
                  dark
                  >{{ item.ActiveStatusTxt }}</v-chip
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="item.PreviewFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click="previewData(item, e)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-file-search</v-icon
                    >
                  </template>
                  <span>Preview</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.EditFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="warning"
                      @click="editAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-file-document-edit</v-icon
                    >
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom v-if="DeleteButtonFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="red"
                      @click="editAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <br />
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    records: {
      required: true,
    },
  },
  data() {
    return {
      tableColumns1: [],
      selected: [],
      search: "",

      AllData: {},
      TableHeader: {},
      TableOptions: {},
      TableData: [],

      ResultFlag: false,
      LoadingFlag: false,

      CreateButtonFlag: false,
      CreatePermission: {},

      DeleteButtonFlag: false,
      DeletePermission: {},
    };
  },
  computed: {},
  watch: {
    CreatePermission: function () {
      console.log("watch CreatePermission");
      var tr = this.CreatePermission;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.CreateButtonFlag = tr.PagePermissionFlag;
      }
    },
    DeletePermission: function () {
      console.log("watch DeletePermission");
      var tr = this.DeletePermission;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.DeleteButtonFlag = tr.PagePermissionFlag;
      }
    },
    AllData: function () {
      console.log("watch AllData");
      var tr = this.AllData;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.TableHeader = tr.TableHeader;
        this.TableOptions = tr.TableOptions;
        this.TableData = tr.TableData;
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      // Get Create Button Permission

      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_members",
        Action: "create",
      };
      this.getPageDetails("CreatePermission", {}, url1, condition1);

      // Get Delete Button Permission

      var url2 = "api/menu-details/show";
      var condition2 = {
        UserInterface: 1,
        Module: "jci_india_members",
        Action: "delete",
      };
      this.getPageDetails("DeletePermission", {}, url2, condition2);

      // Fetch AllData data

      var url3 = "api/year-wise-designation/show";
      var condition3 = {
        UserInterface: 1,
        MemberId: this.records,
      };
      this.getPageDetails("AllData", {}, url3, condition3);
    },
  },
  beforeMount() {
    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    if (RoleId != "") {
      if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
        this.ResultFlag = true;
        this.refreshPageData();
      } else {
        this.ResultFlag = false;
      }
    } else {
      this.$router.push("/access-denied");
    }
  },
};
</script>
